import React,{useState} from 'react'
import Slider from "react-slick";
import { RiStarFill } from "react-icons/ri";
import { HiArrowRight, HiArrowLeft } from "react-icons/hi";
import Title from '../layouts/Title'
import { awsome,  excellence,  rock,  great,  star,  greatjob,  greatjobimg, quote } from "../../assets";


function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="w-14 h-12 bg-[#0c1821] hover:bg-black duration-300 rounded-md text-2xl text-gray-400 flex justify-center items-center absolute top-0 right-0 shadow-shadowOne cursor-pointer z-10"
      onClick={onClick}
    >
      <HiArrowRight />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="w-14 h-12 bg-[#0c1821] hover:bg-black duration-300 rounded-md text-2xl text-gray-400 flex justify-center items-center absolute top-0 right-20 shadow-shadowOne cursor-pointer z-10"
      onClick={onClick}
    >
      <HiArrowLeft />
    </div>
  );
}

const Testimonial = () => {
      const [dotActive, setDocActive] = useState(0);
     const settings = {
       dots: true,
       infinite: true,
       speed: 500,
       slidesToShow: 1,
       slidesToScroll: 1,
       nextArrow:<SampleNextArrow />,
       prevArrow:<SamplePrevArrow />,
       beforeChange: (prev, next) => {
         setDocActive(next);
       },
       appendDots: (dots) => (
         <div
           style={{
             borderRadius: "10px",
             padding: "10px",
           }}
         >
           <ul
             style={{
               display: "flex",
               gap: "15px",
               justifyContent: "center",
               marginTop: "20px",
             }}
           >
             {" "}
             {dots}{" "}
           </ul>
         </div>
       ),
       customPaging: (i) => (
         <div
           style={
             i === dotActive
               ? {
                   width: "12px",
                   height: "12px",
                   color: "blue",
                   background: "#ff014f",
                   borderRadius: "50%",
                   cursor: "pointer",
                 }
               : {
                   width: "12px",
                   height: "12px",
                   color: "blue",
                   background: "gray",
                   borderRadius: "50%",
                   cursor: "pointer",
                 }
           }
         ></div>
       ),
     };
  return (
    <section
      id="testimonial"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title title="WHAT CLIENTS SAY" des="Testimonial" />
      </div>
      <div className="max-w-6xl mx-auto">
        {/* ================ Slider One ================== */}
        <Slider {...settings}>
          <div className="w-full">
            <div className="w-full h-auto flex flex-col lgl:flex-row justify-between">
              <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#1e2024] to-[#23272b] p-8 rounded-lg shadow-shadowOne flex flex-col md:flex-row lgl:flex-col gap-8 justify-center md:justify-start lgl:justify-center">
                <img
                  className="h-72 md:h-32 lgl:h-72 rounded-lg object-cover"
                  src={awsome}
                  alt="awsome"
                />
                <div className="w-full flex flex-col justify-end">
                  <p className="text-xs uppercase text-designColor tracking-wide mb-2">
                    Denver - USA
                  </p>
                  <h3 className="text-2xl font-bold">Ruturaj Jayawant</h3>
                  <p className="text-base tracking-wide text-gray-500">
                  Certified webMethods.io Integration Associate
                  </p>
                </div>
              </div>
              <div className="w-full lgl:w-[60%] h-full flex flex-col justify-between">
                <img className="w-20 lgl:w-32" src={quote} alt="quote" />
                <div className="w-full h-[70%] py-10 bg-gradient-to-r from-[#1e2024] to-[#23272b] rounded-lg shadow-shadowOne p-4 lgl:p-8 flex flex-col justify-center gap-4 lgl:gap-8">
                  <div className="flex flex-col justify-between lgl:items-center py-6 border-b-2 border-b-gray-900">
                    <div>
                      <h3 className="text-xl lgl:text-2xl font-medium tracking-wide">
                        Gates Digital and Web experience Team.
                      </h3>
                      <p className="text-base text-gray-400 mt-3">
                        via LinkedIn - May 13, 2024
                      </p>
                    </div>
                    <div className="text-yellow-500 flex gap-1">
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                    </div>
                  </div>
                  <p className="text-base font-titleFont text-gray-400 font-medium tracking-wide leading-6">
                  I had the privilege of collaborating with Siva for a span of 8 years during our tenure at Gates Corporation. Throughout our time together, Siva consistently demonstrated a keen focus on resolving challenges related to ECM and diverse mobile applications at Gates. Not only was he a dependable team member, but he also showcased a willingness to extend support beyond his immediate responsibilities, leveraging his expertise to assist other teams.I had the privilege of collaborating with Siva for a span of 8 years during our tenure at Gates Corporation. Throughout our time together, Siva consistently demonstrated a keen focus on resolving challenges related to ECM and diverse mobile applications at Gates. Not only was he a dependable team member, but he also showcased a willingness to extend support beyond his immediate responsibilities, leveraging his expertise to assist other teams.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* ================ Slider Two ================== */}

          <div className="w-full">
            <div className="w-full h-auto flex flex-col lgl:flex-row justify-between">
              <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#1e2024] to-[#23272b] p-8 rounded-lg shadow-shadowOne flex flex-col md:flex-row lgl:flex-col gap-8 justify-center md:justify-start lgl:justify-center">
                <img
                  className="h-72 md:h-32 lgl:h-72 rounded-lg object-cover"
                  src={excellence}
                  alt="excellence"
                />
                <div className="w-full flex flex-col justify-end">
                  <p className="text-xs uppercase text-designColor tracking-wide mb-2">
                    Kentucky - USA
                  </p>
                  <h3 className="text-2xl font-bold">Deepthi Thiyagu</h3>
                  <p className="text-base tracking-wide text-gray-500">
                  Architect/SME 
                  </p>
                </div>
              </div>
              <div className="w-full lgl:w-[60%] h-full flex flex-col justify-between">
                <img className="w-20 lgl:w-32" src={quote} alt="quote" />
                <div className="w-full h-[70%] py-10 bg-gradient-to-r from-[#1e2024] to-[#23272b] rounded-lg shadow-shadowOne p-4 lgl:p-8 flex flex-col justify-center gap-4 lgl:gap-8">
                  <div className="flex flex-col justify-between lgl:items-center py-6 border-b-2 border-b-gray-900">
                    <div>
                      <h3 className="text-xl lgl:text-2xl font-medium tracking-wide">
                      Tata Consultancy Services
                      </h3>
                      <p className="text-base text-gray-400 mt-3">
                        via LinkedIn - July 20, 2020
                      </p>
                    </div>
                    <div className="text-yellow-500 flex gap-1">
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                    </div>
                  </div>
                  <p className="text-base font-titleFont text-gray-400 font-medium tracking-wide leading-6">
                  I worked under the supervision of Siva when I was a developer a few years ago. He is a great person to work with. He is technically sound and good at taking care of his team members. Looking forward to working with him if our paths cross down the line.I worked under the supervision of Siva when I was a developer a few years ago. He is a great person to work with. He is technically sound and good at taking care of his team members. Looking forward to working with him if our paths cross down the line.
                  </p>
                </div>
              </div>
            </div>
          </div>

           {/* ================ Slider Three ================== */}

           <div className="w-full">
            <div className="w-full h-auto flex flex-col lgl:flex-row justify-between">
              <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#1e2024] to-[#23272b] p-8 rounded-lg shadow-shadowOne flex flex-col md:flex-row lgl:flex-col gap-8 justify-center md:justify-start lgl:justify-center">
                <img
                  className="h-72 md:h-32 lgl:h-72 rounded-lg object-cover"
                  src={great}
                  alt="great"
                />
                <div className="w-full flex flex-col justify-end">
                  <p className="text-xs uppercase text-designColor tracking-wide mb-2">
                    Denver - USA
                  </p>
                  <h3 className="text-2xl font-bold">Kim Harrington</h3>
                  <p className="text-base tracking-wide text-gray-500">
                  MSR Senior Manager - Senior PM, PMP, CSMMSR Senior Manager - Senior PM, PMP, CSM 
                  </p>
                </div>
              </div>
              <div className="w-full lgl:w-[60%] h-full flex flex-col justify-between">
                <img className="w-20 lgl:w-32" src={quote} alt="quote" />
                <div className="w-full h-[70%] py-10 bg-gradient-to-r from-[#1e2024] to-[#23272b] rounded-lg shadow-shadowOne p-4 lgl:p-8 flex flex-col justify-center gap-4 lgl:gap-8">
                  <div className="flex flex-col justify-between lgl:items-center py-6 border-b-2 border-b-gray-900">
                    <div>
                      <h3 className="text-xl lgl:text-2xl font-medium tracking-wide">
                      Gates Corporation
                      </h3>
                      <p className="text-base text-gray-400 mt-3">
                        via LinkedIn - July 17, 2020
                      </p>
                    </div>
                    <div className="text-yellow-500 flex gap-1">
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                    </div>
                  </div>
                  <p className="text-base font-titleFont text-gray-400 font-medium tracking-wide leading-6">
                  I worked with Siva on several projects with Gates. He was part of a solution development team and has a balanced mix of technical and business skills. He helped lead the team to a better strategic direction on critical activities. His technical skills and business acumen are top notch! I would work with him again any time and you'd be happy to have him a part of your team!I worked with Siva on several projects with Gates. He was part of a solution development team and has a balanced mix of technical and business skills. He helped lead the team to a better strategic direction on critical activities. His technical skills and business acumen are top notch! I would work with him again any time and you'd be happy to have him a part of your team!
                  </p>
                </div>
              </div>
            </div>
          </div>
           {/* ================ Slider Four ================== */}

           <div className="w-full">
            <div className="w-full h-auto flex flex-col lgl:flex-row justify-between">
              <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#1e2024] to-[#23272b] p-8 rounded-lg shadow-shadowOne flex flex-col md:flex-row lgl:flex-col gap-8 justify-center md:justify-start lgl:justify-center">
                <img
                  className="h-72 md:h-32 lgl:h-72 rounded-lg object-cover"
                  src={greatjob}
                  alt="greatjob"
                />
                <div className="w-full flex flex-col justify-end">
                  <p className="text-xs uppercase text-designColor tracking-wide mb-2">
                    Denver - USA
                  </p>
                  <h3 className="text-2xl font-bold">Benjamin George</h3>
                  <p className="text-base tracking-wide text-gray-500">
                  Sr. Software Development Manager, Amazon, MBA.Sr. Software Development Manager, Amazon, MBA.
                  </p>
                </div>
              </div>
              <div className="w-full lgl:w-[60%] h-full flex flex-col justify-between">
                <img className="w-20 lgl:w-32" src={quote} alt="quote" />
                <div className="w-full h-[70%] py-10 bg-gradient-to-r from-[#1e2024] to-[#23272b] rounded-lg shadow-shadowOne p-4 lgl:p-8 flex flex-col justify-center gap-4 lgl:gap-8">
                  <div className="flex flex-col justify-between lgl:items-center py-6 border-b-2 border-b-gray-900">
                    <div>
                      <h3 className="text-xl lgl:text-2xl font-medium tracking-wide">
                      Gates Corporation
                      </h3>
                      <p className="text-base text-gray-400 mt-3">
                        via LinkedIn - July 20, 2020
                      </p>
                    </div>
                    <div className="text-yellow-500 flex gap-1">
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                    </div>
                  </div>
                  <p className="text-base font-titleFont text-gray-400 font-medium tracking-wide leading-6">
                  Siva is one of the most versatile and technically adept software engineers I have had the pleasure to work with in recent years. While Microsoft technologies/.NET are his core strengths, he can ramp up on any tool and technology in no time and deliver results. His bias for action and ownership were evident when he learnt complex enterprise content management tools in no time and helped accounting teams to build better solutions for the customers' invoicing and payments processes. I would love work again with Siva and he would be a great asset to any organization. 
                  </p>
                </div>
              </div>
            </div>
          </div>
           {/* ================ Slider Five ================== */}

           <div className="w-full">
            <div className="w-full h-auto flex flex-col lgl:flex-row justify-between">
              <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#1e2024] to-[#23272b] p-8 rounded-lg shadow-shadowOne flex flex-col md:flex-row lgl:flex-col gap-8 justify-center md:justify-start lgl:justify-center">
                <img
                  className="h-72 md:h-32 lgl:h-72 rounded-lg object-cover"
                  src={greatjobimg}
                  alt="greatjobimg"
                />
                <div className="w-full flex flex-col justify-end">
                  <p className="text-xs uppercase text-designColor tracking-wide mb-2">
                    Kentucky - USA
                  </p>
                  <h3 className="text-2xl font-bold">Mason Roberts</h3>
                  <p className="text-base tracking-wide text-gray-500">
                  Senior Director               </p>
                </div>
              </div>
              <div className="w-full lgl:w-[60%] h-full flex flex-col justify-between">
                <img className="w-20 lgl:w-32" src={quote} alt="quote" />
                <div className="w-full h-[70%] py-10 bg-gradient-to-r from-[#1e2024] to-[#23272b] rounded-lg shadow-shadowOne p-4 lgl:p-8 flex flex-col justify-center gap-4 lgl:gap-8">
                  <div className="flex flex-col justify-between lgl:items-center py-6 border-b-2 border-b-gray-900">
                    <div>
                      <h3 className="text-xl lgl:text-2xl font-medium tracking-wide">
                      Humana Health Care
                      </h3>
                      <p className="text-base text-gray-400 mt-3">
                        via LinkedIn - January 16, 2019
                      </p>
                    </div>
                    <div className="text-yellow-500 flex gap-1">
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                    </div>
                  </div>
                  <p className="text-base font-titleFont text-gray-400 font-medium tracking-wide leading-6">
                  I worked with Siva at Humana over a number of years. My role was as a business owner in the scrum process where Siva was a developer. He was always very pleasant to work with and quick to turn around user stories. He does solid work and had deep development knowledge. I hope we get the opportunity to work together again one day.I worked with Siva at Humana over a number of years. My role was as a business owner in the scrum process where Siva was a developer. He was always very pleasant to work with and quick to turn around user stories. He does solid work and had deep development knowledge. I hope we get the opportunity to work together again one day.
                  </p>
                </div>
              </div>
            </div>
          </div>
           {/* ================ Slider six ================== */}

           <div className="w-full">
            <div className="w-full h-auto flex flex-col lgl:flex-row justify-between">
              <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#1e2024] to-[#23272b] p-8 rounded-lg shadow-shadowOne flex flex-col md:flex-row lgl:flex-col gap-8 justify-center md:justify-start lgl:justify-center">
                <img
                  className="h-72 md:h-32 lgl:h-72 rounded-lg object-cover"
                  src={rock}
                  alt="rock"
                />
                <div className="w-full flex flex-col justify-end">
                  <p className="text-xs uppercase text-designColor tracking-wide mb-2">
Humana Health Care                  </p>
                  <h3 className="text-2xl font-bold">Ravi Kiran Santhanam</h3>
                  <p className="text-base tracking-wide text-gray-500">
                  Sr. Manager - Projects / Lead Product ConsultantSr. Manager - Projects / Lead Product Consultant
                  </p>
                </div>
              </div>
              <div className="w-full lgl:w-[60%] h-full flex flex-col justify-between">
                <img className="w-20 lgl:w-32" src={quote} alt="quote" />
                <div className="w-full h-[70%] py-10 bg-gradient-to-r from-[#1e2024] to-[#23272b] rounded-lg shadow-shadowOne p-4 lgl:p-8 flex flex-col justify-center gap-4 lgl:gap-8">
                  <div className="flex flex-col justify-between lgl:items-center py-6 border-b-2 border-b-gray-900">
                    <div>
                      <h3 className="text-xl lgl:text-2xl font-medium tracking-wide">
                      Tata Consultancy Services
                      </h3>
                      <p className="text-base text-gray-400 mt-3">
                        via LinkedIn - July 14, 2016
                      </p>
                    </div>
                    <div className="text-yellow-500 flex gap-1">
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                    </div>
                  </div>
                  <p className="text-base font-titleFont text-gray-400 font-medium tracking-wide leading-6">
                  Siva has been a valued member of the team by always being someone we can count on, a go to person for any .net/Wcf related stuff. He always tries to stay ahead of the game by gathering all the information that will allow him to meet the deadlines the clients demand.. Good team player. I have personally appreciated him several times on how he approaches difficult situations with a 'can do' attitude.. Sure, he will go places..Would love to work with him or have him in my team any day..                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* ================ Slider Seven ================== */}

          <div className="w-full">
            <div className="w-full h-auto flex flex-col lgl:flex-row justify-between">
              <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#1e2024] to-[#23272b] p-8 rounded-lg shadow-shadowOne flex flex-col md:flex-row lgl:flex-col gap-8 justify-center md:justify-start lgl:justify-center">
                <img
                  className="h-72 md:h-32 lgl:h-72 rounded-lg object-cover"
                  src={star}
                  alt="star"
                />
                <div className="w-full flex flex-col justify-end">
                  <p className="text-xs uppercase text-designColor tracking-wide mb-2">
                    Denver  - USA
                  </p>
                  <h3 className="text-2xl font-bold">Suvendu Samal</h3>
                  <p className="text-base tracking-wide text-gray-500">
                  Technical Leader | Software Architecture | AWSTechnical Leader | Software Architecture | AWS
                  </p>
                </div>
              </div>
              <div className="w-full lgl:w-[60%] h-full flex flex-col justify-between">
                <img className="w-20 lgl:w-32" src={quote} alt="quote" />
                <div className="w-full h-[70%] py-10 bg-gradient-to-r from-[#1e2024] to-[#23272b] rounded-lg shadow-shadowOne p-4 lgl:p-8 flex flex-col justify-center gap-4 lgl:gap-8">
                  <div className="flex flex-col justify-between lgl:items-center py-6 border-b-2 border-b-gray-900">
                    <div>
                      <h3 className="text-xl lgl:text-2xl font-medium tracking-wide">
                        Gates Corporation
                      </h3>
                      <p className="text-base text-gray-400 mt-3">
                        via LinkedIn - July 18, 2020
                      </p>
                    </div>
                    <div className="text-yellow-500 flex gap-1">
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                      <RiStarFill />
                    </div>
                  </div>
                  <p className="text-base font-titleFont text-gray-400 font-medium tracking-wide leading-6">
                  I found Siva to be a responsive and helpful member of our team. He was always positive, and worked with me to enhance services, server upgrades , .Net  applications and websites in Gates. He was proactive and willing to try something new, if he thought it might help. Good on his learning skills. I would work with him again, if the opportunity arose.I found Siva to be a responsive and helpful member of our team. He was always positive, and worked with me to enhance services, server upgrades , .Net  applications and websites in Gates. He was proactive and willing to try something new, if he thought it might help. Good on his learning skills. I would work with him again, if the opportunity arose.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
}

export default Testimonial