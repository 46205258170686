import React from 'react'
import Title from '../layouts/Title'
import { integrations,systemupgrade, systemmigration,mobileappdev,analytics,websitebuild } from "../../assets/index";
import ProjectsCard from './ProjectsCard';

const Projects = () => {
  return (
    <section
      id="projects"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title
          title="VISIT MY PORTFOLIO AND KEEP YOUR FEEDBACK"
          des="My Projects"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        <ProjectsCard
          title="Migrations"
          des=" With over a decade of experience, I specialize in seamless migrations across diverse technology landscapes. My expertise includes upgrading .NET Framework applications, migrating Windows Server environments, and modernizing legacy systems such as Access DB, Access applications, Classic ASP, and VB6 to the latest technology stacks, ensuring enhanced performance, security, and scalability."
          src={systemmigration}
        />
        <ProjectsCard
          title="Upgrades"
          des="Expertly led numerous high-impact projects, driving comprehensive upgrades and updates that significantly enhance system performance, security, and scalability. My leadership spans diverse platforms and technologies, ensuring each project delivers robust, future-proof solutions tailored to meet evolving business needs."
          src={systemupgrade}
        />
        <ProjectsCard
          title="Integrations"
          des="With a proven track record of seamlessly integrating diverse systems, I specialize in leveraging web services, Web APIs, third-party APIs, AWS API Gateway, and Lambda functions. My expertise ensures robust, scalable, and efficient solutions that enhance functionality and performance, driving business success through innovative and reliable integration strategies."
          src={integrations}
        />
        <ProjectsCard
          title="Mobile Appliactions"
          des=" Transforming innovative ideas into scalable mobile solutions, I leverage my expertise as a Mobile Application Solutions Architect to design, optimize, and deliver seamless user experiences across platforms. With a keen eye for emerging technologies and a strategic approach to problem-solving, I ensure each application is robust, efficient, and primed for future growth."
          src={mobileappdev}
        />
        <ProjectsCard
          title="Planning, Forcasting and Analytics"
          des=" Specializing in Planning, Forecasting, and Analytics, I harness data-driven insights to drive strategic decisions and optimize performance. My expertise ensures accurate forecasting, effective planning, and actionable analytics, empowering organizations to navigate complexities and achieve sustainable growth."
          src={analytics}
        />
        <ProjectsCard
          title="Web tools and Websites"
          des="Bringing 15 years of expertise as a Website Solutions Expert, I deliver comprehensive, high-impact web solutions that blend innovation with functionality. My proficiency in cutting-edge technologies ensures seamless, scalable, and visually captivating websites tailored to drive business success."
          src={websitebuild}
        />
      </div>
    </section>
  );
}

export default Projects