import React from "react";
import {motion} from "framer-motion"
import ResumeCard from "./ResumeCard";

const Experience = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="py-12 font-titleFont flex gap-20"
    >
      <div>
        <div className="flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2010 - 2022</p>
          <h2 className="text-4xl font-bold">Expert Level Experience</h2>
        </div>
        <div className="mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Senior System Specialist"
            subTitle="Gates Corporation - (2022 - Present)"
            result="USA"
            des="Web and Mobile Experience -As a Senior System Specialist, I am assessing potential risks and vulnerabilities within systems and developing strategies to mitigate them. This involves implementing security measures, conducting regular audits, and staying updated on industry best practices to ensure compliance with relevant regulations and standards."
          />
          <ResumeCard
            title="Senior System Analyst"
            subTitle="Gates Corporation - (2016 - 2022)"
            result="USA"
            des="As an individual contributor work on Multi-Tier architecture to setup tenant and Sub tenant fault tolerant infrastructure."
          />
          <ResumeCard
            title="Senior.Net Developer"
            subTitle="Technology People - (2014 - 2016)"
            result="USA"
            des="To explore new innovative thoughts and process by analyzing the BRD and business risk and issues.Brian storming with peer end points SME (Amazon, Oracle, SAP, Esker, Infor EAM, Digital Assets, infrastructure, security, Rackspace, EMC, OpenText, Microsoft) respective to the projects."
          />
        </div>
      </div>
      <div>
        <div className="flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2001 - 2020</p>
          <h2 className="text-4xl font-bold">Entry Level Experience</h2>
        </div>
        <div className="mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="IT Analyst/ Scrum Master"
            subTitle="Tata Consultancy Services (2010 - 2014)"
            result="USA"
            des="Extensively Worked on Agile and Scrum methodolog and projects"
          />
          <ResumeCard
            title="WPF Developer"
            subTitle="SyncFusion (2009 - 2010)"
            result="INDIA"
            des="Had a chance to learn WPF and Created WPF user controls as Sync Fusion is the competitor for the Microsoft Tools and Plugin's. I had better choices to get expertise with the job and had a ladder for my career in learning WPF/MVVM and user controls with advance UI graphics and enhancement's."
          />
          <ResumeCard
            title="System Analyst"
            subTitle="BSS Software Services (2007 - 2009)"
            result="INDIA"
            des="Involved in customizing, testing, debugging, application evaluation and implementation. Developed Generic Data adaptors for Health care application. Worked on presentation, business and data access layer. Responsible for Design and Development of Web forms in ASP.Net using C#."
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Experience;
