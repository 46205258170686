import React from 'react'
import { motion } from 'framer-motion';
import ResumeCard from './ResumeCard';

const Education = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      {/* part one */}
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
        <p className="text-sm text-designColor tracking-[4px]">1983 - 2005</p>
          <h2 className="text-3xl md:text-4xl font-bold">Personal Details</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Bachelor’s in Engineering"
            subTitle="From Anna University (2001 - 2005)"
            result="3.90/4"
            des="Bachelors of Engineering in Electronics and Communication from Anna University (2001- 2005)"
          />
          <ResumeCard
            title="Secondary School Education"
            subTitle="Kingstar Secondary School (1998 - 2000)"
            result="5.00/5"
            des="Secondary education or post-primary education covers two phases on the International Standard Classification of Education scale."
          />
          <ResumeCard
            title="Nationality"
            subTitle="Born on Oct 06th 1983"
            result="Indian"
            des="Require H1b Sponsorship to work in USA."
          />

        </div>
      </div>
      {/* part Two */}

      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2005 - 2024</p>
          <h2 className="text-3xl md:text-4xl font-bold">Job Experience</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Sr.System Specialist, Web & Mobile Experience"
            subTitle="Gates Corporation 2014- 2024"
            result="Denver, USA- 10 years"
            des="Within this role, On a daily basis, my responsibilities revolve around identifying areas for enhancement, implementing strategies, and ensuring that our customers have seamless and user-friendly digital experiences."
            // I actively seek opportunities to enhance the long-term customer experience by focusing on the improvement of digital tools and applications utilized by our esteemed clientele.
          />
          <ResumeCard
            title="IT Analyst"
            subTitle="Tata Consultancy Services - (2010 - 2014)"
            result="Kentucky, USA- 2 years"
            des="In this role, I am involved in designing and developing system architectures that align with business objectives and meet functional requirements. This includes proposing new solutions to enhance efficiency, scalability, and reliability." 
            //"
          />
          <ResumeCard
            title="Multiple Role in multiple companies"
            subTitle="TCS (2010 to 2012),Syncfusion (2009-2010), BSS(2007),Izone (2005 - 2007)"
            result="India - 7 Years"
            des="In this time frame , Involved in customizing, testing, debugging, application evaluation and implementation.Responsible for Design and Development of Web forms in ASP.Net using C#."
            //Developed Generic Data adaptors for Health care application. Browser Compatibility has been handled for business solutions. Worked on presentation, business and data access layer. Responsible for Design and Development of Web forms in ASP.Net using C#. Designed and developed C# classes, Used extensively data grids in ASP.Net, Web forms. Developed stored procedures. Developed JavaScript to support AJAX.
          />
        </div>
      </div>
    </motion.div>
  );
}

export default Education