import { AiFillAppstore } from "react-icons/ai";
import { FaMobile, FaGlobe} from "react-icons/fa";
import { SiProgress, SiAntdesign } from "react-icons/si";

// Features Data
export const featuresData = [
  {
    id: 1,
    title: "IT Solutions Architect",
    des: "Crafting seamless IT solutions as an expert Solutions Architect, blending innovation with precision to drive business success",
  },
  {
    id: 2,
    icon: <AiFillAppstore />,
    title: "Full-Stack Developer",
    des: "Transforming innovative ideas into robust IT solutions with expert software development skills and a passion for excellence.",
  },
  {
    id: 3,
    icon: <SiProgress />,
    title: "Project Management",
    des: "I build long-term meaningful relationships and truly believe in delivering value through continuous improvement. I turn ideas into reality, on time and on budget.",
  },
  {
    id: 4,
    icon: <FaMobile />,
    title: "Mobile Development",
    des: "Designing intuitive mobile apps and delivering cutting-edge solutions to elevate user experiences and drive success.I bridge the gap between mobile app ideas and reality.",
  },
  {
    id: 5,
    icon: <SiAntdesign />,
    title: "Rapid Learner",
    des: "Mastering New Skills at the Speed of Innovation.I am Flexible, Adaptable and eager - I learn fast to keep up with the ever-changing tech landscape",
  },
  {
    id: 6,
    icon: <FaGlobe />,
    title: "Hosting Websites",
    des: "Scale your web app effortlessly with my AWS serverless hosting solutions.Focus on your business, not your server. I build serverless web hosting on AWS.",
  },
];
